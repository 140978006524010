'use client';

import { useMarket } from '@app/_context';
import FacebookButton from '@auth/facebookButton';
import GoogleButton from '@auth/googleButton';
import { Input } from '@components/inputs';
import Link from '@components/Link';
import { Heading } from '@components/ui/Heading/Heading';
import { Paragraph } from '@components/ui/Paragraph/Paragraph';
import type { AuthState } from '@context/authContext';
import { PROVIDER_ERRORS, useAuth } from '@context/authContext';
import { useSiteInfo } from '@context/siteInfoContext';
import { Button, Divider } from '@ngg/components';
import { useQuery } from '@tanstack/react-query';
import type { FormEvent } from 'react';

import { cn } from '@/lib/utils';

export default function LoginView() {
    const { locale } = useMarket().state.market;
    const { data } = useQuery<AuthState>({ queryKey: ['auth'] });
    const { showError, showReset, hideLogin } = useAuth();
    const { loginWithEmail, loginWithGoogle, loginWithFacebook } = useAuth();
    const { dictionary: globalDictionary, myPagesRegisterPage } = useSiteInfo();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = formData.get('email')?.toString();
        const password = formData.get('password')?.toString();
        if (!email) return showError('Missing email');
        if (!password) return showError('Missing password');
        return loginWithEmail(email, password);
    };

    return (
        <>
            <Heading className={cn('text-lg font-bold')}>
                {globalDictionary.login}
            </Heading>
            <Paragraph className="mt-4 text-sm">
                {globalDictionary.loginDialogSubheading}
            </Paragraph>
            <div className="mt-6 grid grid-cols-2 gap-x-3.5 gap-y-2">
                <FacebookButton onClick={loginWithFacebook}>
                    {globalDictionary.loginWithFacebook}
                </FacebookButton>
                <GoogleButton onClick={loginWithGoogle}>
                    {globalDictionary.loginWithGoogle}
                </GoogleButton>
                {data?.error && PROVIDER_ERRORS.includes(data.error) && (
                    <Paragraph className="col-span-full text-center text-xs text-error">
                        {globalDictionary[data.error]}
                    </Paragraph>
                )}
            </div>

            <div className="flex items-center gap-1 py-6 text-xs uppercase text-grey-300">
                <Divider className="h-px min-w-0 flex-1" />
                {globalDictionary?.or}
                <Divider className="h-px min-w-0 flex-1" />
            </div>

            <form className="space-y-4" onSubmit={handleSubmit}>
                <Input
                    classNames={{ wrapper: 'w-full' }}
                    name="email"
                    id="email"
                    type="email"
                    label={`${globalDictionary?.formEmailLabel}*`}
                />

                <Input
                    classNames={{ wrapper: 'w-full' }}
                    name="password"
                    id="password"
                    type="password"
                    label={`${globalDictionary?.formPasswordLabel}*`}
                />

                <Button
                    type="submit"
                    isLoading={data?.loading}
                    className="btn-primary mt-4"
                    fullWidth>
                    {globalDictionary.login}
                </Button>
                {data?.error && !PROVIDER_ERRORS.includes(data.error) && (
                    <>
                        <Paragraph className="mt-2 text-sm text-error">
                            {globalDictionary.errorLoginText}
                        </Paragraph>
                        <div className="flex flex-col gap-4 bg-grey-100 px-4 py-6">
                            <Heading className="text-xl">
                                {globalDictionary?.errorCantAccessAccount}
                            </Heading>
                            <Paragraph className="text-sm">
                                {globalDictionary?.errorMigratedAccount}
                            </Paragraph>
                        </div>
                    </>
                )}
            </form>
            <div className="my-4">
                <button
                    type="button"
                    onClick={() => showReset()}
                    className="text-sm text-grey-300 underline hover:opacity-70">
                    {globalDictionary.forgotPassword}
                </button>
            </div>

            <hr className="my-4 w-full border-grey-150" />

            <Paragraph className="text-sm text-grey-300">
                {globalDictionary.noAccount}&nbsp;
                {myPagesRegisterPage?.slug ? (
                    <Link
                        locale={locale}
                        className="text-black underline"
                        href={myPagesRegisterPage.slug}
                        onClick={() => hideLogin()}>
                        {globalDictionary.register}
                    </Link>
                ) : null}
            </Paragraph>
        </>
    );
}
