import type { ButtonProps } from '@ngg/components';
import { Button } from '@ngg/components';

import { cn } from '@/lib/utils';

const FbLogo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="31"
        viewBox="0 0 32 31"
        fill="none">
        <path
            d="M30.1182 0.700195H2.44506C1.49669 0.700195 0.728271 1.43521 0.728271 2.34211V28.8083C0.728271 29.7151 1.49681 30.4502 2.44506 30.4502H17.3433V18.9295H13.2895V14.4396H17.3433V11.1284C17.3433 7.2858 19.7972 5.19345 23.3814 5.19345C25.0982 5.19345 26.5739 5.31573 27.0038 5.37035V9.38608L24.518 9.38719C22.5687 9.38719 22.1913 10.273 22.1913 11.573V14.4396H26.8402L26.2349 18.9295H22.1914V30.4502H30.1183C31.0664 30.4502 31.8351 29.715 31.8351 28.8083V2.34211C31.8349 1.4351 31.0663 0.700195 30.1182 0.700195Z"
            fill="white"
        />
    </svg>
);

export default function FacebookButton<C extends React.ElementType = 'button'>({
    children,
    className,
    ...props
}: ButtonProps<C>) {
    return (
        <Button
            className={cn(
                'rounded-[3px] border-[#475A96] bg-[#475A96] py-0.5 pl-0.5 pr-0 text-xs text-white hover:border-[#475A96]/90 hover:bg-[#475A96]/90',
                className,
            )}
            startIcon={<FbLogo />}
            // @todo Do not use any
            {...(props as any)}>
            {children}
        </Button>
    );
}
