import { useMarket } from '@app/_context';
import Link from 'next/link';
import type { Dictionary } from 'types';

export const NewCustomerHeader = ({
    dictionary,
    onClose,
}: {
    dictionary: Dictionary;
    onClose?: () => void;
}) => {
    const { locale } = useMarket().state.market;
    return (
        <div className="flex max-w-72 flex-col">
            <h2 className="text-lg font-bold">
                {dictionary?.newCustomerFormHeading}
            </h2>

            <p className="mb-6 mt-3 flex-1 text-sm">
                {dictionary?.newCustomerFormInfoText}
            </p>
            <p className="text-xs">
                {' '}
                {dictionary?.newCustomerFormTermsText}{' '}
                <Link
                    locale={locale}
                    className="underline"
                    onClick={onClose}
                    href={dictionary?.newCustomerFormTermsLink}>
                    {dictionary?.newCustomerFormTermsLinkText}
                </Link>
            </p>
        </div>
    );
};
